module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-mdx-virtual-9b1ad09498/0/cache/gatsby-plugin-mdx-npm-3.17.0-7a32cbbc06-1deab67e64.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-react-i18next-virtual-d5f1871095/0/cache/gatsby-plugin-react-i18next-npm-2.0.1-8506914cc0-f05d5f3e44.zip/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","siteUrl":"https://onichandame.com","languages":["en","cn"],"defaultLanguage":"en","i18nextOptions":{"preload":["en","cn"],"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-1763a62fc4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
